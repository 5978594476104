<template>
  <div class="text-center">
    <div class="row my-auto" style="height: 100vh;">
      <div class="col my-auto">
        <img class="qrCode-img img-fluid"
             :src="classroom.qr_code_url"
             alt="QR Code" />
      </div>
      <div class="col my-auto">
        <p class="classroom-name">
          {{ classroom.classroom_name }}'s Classroom Code:
        </p>
        <p class="classroom-code">
          {{ classroom.classroom_code }}
        </p>
        <!-- <br><br>
        <img class="printer"
             src="../../../assets/images/printer.png"
             alt="Printer"
             @click="print" /> -->
      </div>
    </div>
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    name   : 'QRCode',
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        classroom : null,
      }
    },
    mounted() {
      this.getClassroom();
    },
    methods : {

      /**
       * Get Classroom Data
       */
      getClassroom() {
        this.$http.get('/api/classroom', {
          params : {
            classroomId : this.$route.params.classroomId,
          },
        }).then((response) => {
          this.classroom = response.data;
        });
      },

      /**
       * Print QR Code
       */
      // print() {
      //   window.print();
      // }
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../assets/scss/components/user/classroom/qr-code';
</style>
